import React from "react"
import { Helmet } from "react-helmet"
import "../styles/main.scss"

export default class Layout extends React.Component<any> {
  render() {
    return (
      <>
        <Helmet>
          <title>Baby Templeton</title>
          <meta
            property="description"
            content="Let's celebrate the birth of Baby Templeton!"
          />
        </Helmet>
        <main>{this.props.children}</main>
      </>
    )
  }
}
